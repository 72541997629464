<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <Alert :message="requestError" type="alert-danger" v-if="requestError" />
    <div class="row">
      <div class="col-md-12 content_margin_bottom">
        <div class="row">
          <div class="col-md-12 mb-4">
            <!-- <h4 class="card-title my-3 float-right">
              <router-link
                :to="`/user/${userId}/enroll_courses`"
                class="btn btn-success btn-sm mr-3"
                >My Courses</router-link
              >
            </h4> -->
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items="certificates"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sorrt-desc.sync="sortDesc"
                :fit-by.sync="sortBy"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                hover
              >
                <template v-slot:cell(action)="row">
                  <div class="dropdown">
                    <a
                      href="#"
                      data-toggle="dropdown"
                      data-caret="false"
                      class="text-muted"
                      ><b-icon-three-dots></b-icon-three-dots
                    ></a>
                    <div class="dropdown-menu dropdown-menu-right p-3">
                      <button
                        @click="sendRequest(row.item.id, 'accept')"
                        class="btn text-success"
                      >
                        Accept
                      </button>

                      <button
                        @click="sendRequest(row.item.id, 'decline')"
                        class="btn text-danger"
                      >
                        Deny
                      </button>
                    </div>
                  </div>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "@/views/layouts/main.vue";
import PageHeader from "@/components/page-header";
import Alert from "@/components/Alert.vue";
import appConfig from "@/app.config";
import store from "@/state/store";

export default {
  page: {
    title: "Hire",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Alert,
    PageHeader,
    Layout,
  },
  data() {
    return {
      // data table meta
      userId: this.$route.params.id,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "course",
      sortDesc: false,
      fields: [
        { key: "id", sortable: true, label: "ID" },
        { key: "full_name", sortable: true, label: "Full Name" },
        { key: "username", sortable: true, label: "Email" },
        { key: "phone", sortable: true, label: "Contact" },
        { key: "status", sortable: true, label: "Status" },
        { key: "Action" },
      ],
      //end of table meta
      loading: false,
      id: null,
      requestError: null,
      success: null,
      error: null,
      key: null,
      title: "Hire",
      items: [
        {
          text: "User",
        },
        {
          text: "Hire",
          active: true,
        },
      ],
      certificates: [],
      certPDF: null,
    };
  },
  methods: {
    sendRequest(enrollmentId, action) {
      const data = {
        action: action,
        request_id: enrollmentId,
      };
      console.log(data);
      store
        .dispatch("hiring/hireAction", data)
        .then((response) => {
          this.toast({ message: response.status, type: "success" });
        })
        .catch(() => {
          this.loading = false;
          this.success = false;
        });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  computed: {
    rows() {
      return this.certificates && this.certificates.length;
    },
  },
  created() {
    store
      .dispatch("hiring/fetchAllRecruiterRequests", this.userId)
      .then((response) => {
        console.log(response, "response");
        let certs = response.data.requests;

        const users = certs.map((user) => {
          return {
            id: user.id,
            status: user.status,
            full_name: `${user.recruiter.first_name} ${user.recruiter.other_names} ${user.recruiter.last_name}`,
            username: user.recruiter.email,
            phone: user.recruiter.phone,
            technician_id: user.technician_id,
            recruiter_id: user.recruiter_id,
          };
        });
        this.certificates = users;

        console.log(certs);
        this.totalRows = this.certificates.length;
      })
      .catch((err) => {
        if (err?.response?.data === undefined)
          this.requestError = "An error occured";
      });
  },
};
</script>
<style scoped>
th {
  text-transform: uppercase;
}
</style>
